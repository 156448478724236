@import "src/css/index.scss";

.container {
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  position: absolute;
  bottom: 1em;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbutton {
  @include heading;
  appearance: none;
  border: none;
  margin: 0 0.5em;
  padding: 0;
  background: transparent;

  &:hover {
    background: transparent;
  }
}

.submit {
  composes: navbutton;

  margin-left: auto;
}

.radioGroup {
  display: flex;
}

.radio {
  label {
    @include paragraph($weight: bold);

    display: block;
    margin: 0.75em 1.5em 0 0;
    padding: 0.5em 0.75em;
    border: 4px solid black;
    min-width: 4.375em;

    &:not(:last-child) {
      margin-right: 1.5em;
    }
  }

  &:global(.rs-radio-checked) label {
    background-color: black;
    color: white;
    text-decoration: underline;
  }

  & :global(.rs-radio-wrapper),
  & :global(.rs-radio-wrapper) > input {
    @include visually-hidden;
  }

  & > :global(.rs-radio-checker) {
    padding: 0;
  }
}

label,
.label {
  @include paragraph($size: medium);
  margin-bottom: 0.25em;
}

input[type="text"] {
  @include paragraph();

  border: 4px solid black;
  height: 2.5em;
  padding: 0.5em;
}

:global(.rs-form-control-wrapper) {
  position: relative;
  margin-bottom: 1em;
}

.heading {
  @include heading;
  max-width: 9.8em;
  margin-top: 2em;
  margin-bottom: 0.75em;
}

.subheading {
  @include paragraph();
  display: block;
  margin-top: 0.5em;
}

:global(.rs-error-message-inner) {
  font-size: 0.75em;
}

:global(.rs-error-message-wrapper) {
  position: absolute;
}

.actuallySubmit {
  @include paragraph($weight: bold);

  border: 4px solid black;
  padding: 0.25em 0.5em;
}

.question {
  @include paragraph($size: medium);

  margin-bottom: 0;
  max-width: 12.5em;

  &:not(:first-child) {
    margin-top: 1.25em;
  }
}

.additionInfo .question {
  margin: 0;
}

.imageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;

  .heading {
    max-width: 7em;
    text-align: center;
  }

  & > form {
    display: flex;
    align-items: center;
    border: 4px solid black;
    width: 100%;
    height: 16.875em;
  }

  input {
    @include visually-hidden;
  }

  button:not(.navbutton) {
    @include paragraph($weight: bold);
    padding: 0.5em;
    border: 4px solid black;
    appearance: none;
    margin-bottom: 1em;
    background: transparent;
    display: block;

    &:hover {
      background: transparent;
    }

    &::after {
      content: ".";
      @include paragraph($weight: bold);
    }
  }
}

.imagequestion {
  @include paragraph($size: medium);
  max-width: 8.3em;
  text-align: center;
}
